import {
  Button,
  Stack,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import { getAssetURL, getStaticAssetURL } from "../../utils/helperFuncations";
import DesAvatar from "../Inputs/DesAvatar";
import { useSelector } from "react-redux";
import useContentHook from "../../hooks/useContentHook";

// component name changed from  base map card to map card to because i use this component card at base map and layer map service
export default function MapCard(props) {
  const { getContentText } = useContentHook();

  const language = useSelector((state) => state.translation.language);
  // open pop up iframe  from  redux to open base mab iframe

  return (
    <Card sx={cardStyle}>
      {/* map image */}
      <Box sx={{ position: "relative" }}>
        <CardMedia
          sx={imgStyle}
          component="img"
          image={props.imgSrc || getStaticAssetURL("defaultMapImg.png")}
          alt={props.name}
          // this event run when the image have issue at get image src and instead of errors it show me default image
          onError={(e) => {
            e.target.src = getStaticAssetURL("defaultMapImg.png");
          }}
        />

        {props.plan === "premium" && (
          <DesAvatar
            style={{
              p: 0.5,
              position: "absolute",
              zIndex: 3,
              top: 20,
              left: language === "ar" && 20,
              right: language === "en" && 20,
            }}
            text={getContentText("baseMaps_premium")}
            src={getStaticAssetURL("mapservice-premium-icon.png")}
            place={
              "left" // control pop up place
            }
          />
        )}
      </Box>

      <CardContent sx={contentStyle}>
        {/* title */}
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          sx={textStyle}
          color="secondary"
        >
          {props.name || ""}
        </Typography>

        {/* descriptions  */}
        <Typography variant="body2" color="text.secondary" sx={desStyle}>
          {props?.des}
        </Typography>

        <Stack
          direction="row"
          spacing={1.5}
          pt={2.5}
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Stack direction="row" spacing={1.5}>
            <Button
              size="small"
              variant="contained"
              onClick={props.showFun}
              sx={btnStyle}
            >
              {getContentText("baseMaps_show")}
            </Button>

            <Button
              size="small"
              variant="outlined"
              onClick={props.useFun}
              sx={btnStyle}
            >
              {getContentText("baseMaps_use")}
            </Button>
          </Stack>
          {props.category?.icon?.id && (
            <DesAvatar
              style={{ p: 1, borderColor: "primary.main" }}
              text={props.category?.translations[0]?.title}
              src={getAssetURL(props.category.icon.id)}
              place={
                language === "en" ? "left" : "right" // control pop up place
              }
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

const cardStyle = {
  boxShadow: 3,
  borderRadius: "25px ",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  "&:hover ": { boxShadow: 6 },
};
const textStyle = {
  fontWeight: "600",
  mb: 1.5,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textTransform: "lowercase",
  fontSize: {
    xs: "1.05rem",
  },
};

const imgStyle = { px: 1.5, pt: 1.5, height: "250px", borderRadius: "25px" };

const btnStyle = {
  minWidth: "85px",
  boxShadow: "none !important",
};

const desStyle = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textTransform: "lowercase",
};

const contentStyle = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
};
