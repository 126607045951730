import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DesBtn from "../Inputs/DesBtn";
import Typography from "@mui/material/Typography";
import DoneIcon from "@mui/icons-material/Done";
import { useState } from "react";
import Box from "@mui/material/Box";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useContentHook from "../../hooks/useContentHook";

const CopyArea = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  const { getContentText } = useContentHook();



  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2500);
  };

  return (
    <Box component="code" sx={boxStyle}>
      <Typography variant="h6" component="h6" sx={textStyle}>
        {props.text || props.children}
      </Typography>

      <Box sx={{ textAlign: "end", width: "40px" }}>
        <DesBtn copy text={getContentText("pages_keys_copyButtonTitle")}>
          <CopyToClipboard text={props.text || props.children} onCopy={handleCopy}>
            {isCopied ? (
              <DoneIcon sx={{ fontSize: "1rem" }} />
            ) : (
              <ContentCopyIcon sx={{ fontSize: "1rem" }} />
            )}
          </CopyToClipboard>
        </DesBtn>
      </Box>
    </Box>
  );
};

export default CopyArea;

const textStyle = {
  fontSize: { xs: ".7rem", sm: ".9rem", md: ".8rem" },
  textAlign: "start",
  whiteSpace: "nowrap  ",
  overflowX: "auto ",
  overflowY: "hidden ",
  textOverflow: "clip ",
  width: "calc(100% - 40px)",
  boxSizing: "content-box",
  padding: "4px 0px ",
};

const boxStyle = {
  background: "#ededed",
  padding: "4px 6px ",
  borderRadius: "5px",
  textAlign: "center",
  width: "100%",
  display: "flex",
};
