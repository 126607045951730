import {
  List,
  InputAdornment,
  OutlinedInput,
  FormControl,
  Typography,
  ListItem,
  ListItemButton,
  Box,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { tabaqatKeyAutoComplete } from "../../utils/StaticVariables";
import useContentHook from "../../hooks/useContentHook";
import { geocodingUrl } from "../../utils/StaticVariables";

export default function AutoCompletePlaces(props) {
  const [selected, setSelected] = useState({});
  const [listShowed, setListShowed] = useState(false);
  const [autocompleteOption, setAutocompleteOption] = useState([]);
  const [val, setVal] = useState("");
  const { getContentText } = useContentHook();
  const language = useSelector((state) => state.translation.language);

  useEffect(() => {
    if (val === selected.label || !val) return;
    axios
      .get(geocodingUrl, {
        params: {
          text: val,
          access_token: tabaqatKeyAutoComplete,
          lang: language,
          size: "6",
        },
      })
      .then((res) => {
        setAutocompleteOption(res.data.features);
        setListShowed(true);
      })
      .catch((err) => {
        console.log(err);
        setListShowed(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val, language]);

  const changeHandle = (event) => {
    setVal(event.target.value);
  };

  const handleListItemClick = (el) => {
    setSelected(el?.properties);
    setVal(el?.properties.label);
    setListShowed(false);
    props.updateCoordinates(el?.geometry.coordinates);
  };

  return (
    <Box sx={{ height: " 100%", p: 3 }} onClick={() => setListShowed(false)}>
      <Typography sx={{ fontWeight: 700 }} color="#fff" gutterBottom>
        {getContentText("geoCoding_label")}
      </Typography>
      <FormControl fullWidth color="info" sx={inputStyle} variant="outlined">
        <OutlinedInput
          fullWidth
          value={val}
          onChange={changeHandle}
          endAdornment={
            <InputAdornment position="end">
              <SearchIcon color="info" />
            </InputAdornment>
          }
        />
      </FormControl>
      {listShowed ? (
        <List
          component="aside"
          sx={{ background: "white", borderRadius: "5px", boxShadow: 3 }}
        >
          {autocompleteOption.length ? (
            autocompleteOption.map((el) => (
              <ListItemButton
                key={el?.properties.id}
                selected={el?.properties.id === selected?.id}
                onClick={() => handleListItemClick(el)}
              >
                <ListItemText primary={el?.properties.label} />
              </ListItemButton>
            ))
          ) : (
            <ListItem>
              <ListItemText
                primary={getContentText(`geoCoding_notFound ${val}`)}
              />
            </ListItem>
          )}
        </List>
      ) : null}
    </Box>
  );
}

const inputStyle = {
  my: 1,
  background: "white",
  borderRadius: "4px",
  outline: "#16aa9d 1px  solid",
};
