import axios from "axios";
import CheckBoxReusable from "../Inputs/CheckBoxReusable";
import InputTextCustom from "../Inputs/InputTextCustom";
import ReusablePopUp from "../PopUp/ReusablePopUp";
import InputWithBtn from "../Inputs/InputWithBtn";
import ReuseableSwitch from "../Inputs/ReuseableSwitch";
import ReuseableSelectedBtn from "../Inputs/ReuseableSelectedBtn";
import useContentHook from "../../hooks/useContentHook";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { typeFilterData } from "../../utils/StaticVariables";
import { catalogTabaqatApi } from "../../utils/StaticVariables";
import { useState, useEffect } from "react";
import { Button, Stack, Box, Divider, Typography, Grid } from "@mui/material";

const ControlOfLayerBaseMapArea = (props) => {
  const language = useSelector((state) => state.translation.language);
  const countryCode = useSelector((state) => state.country.countrySlice);

  const [isOpen, setIsOpen] = useState(false);
  const [categoriesResArr, setCategoriesResArr] = useState([]); // categories data array response
  const { getContentText } = useContentHook();

  const formik = useFormik({
    initialValues: {
      search: "",
      type: [],
      category: [],
      featured: true,
    },

    onSubmit: (values) => {
      props.filterHandle(values);
    },
  });

  useEffect(() => {
    axios
      .get(catalogTabaqatApi + "/workspace", {
        // api link - the catalog api at static variables file
        params: {
          fields: "name,id,icon.id,translations.*", // select fields i need from directus

          filter: {
            status: { _eq: "published" },
            organizations: {
              organization_id: {
                code: {
                  _eq: countryCode,
                },
              },
            },
          },

          deep: {
            translations: {
              _filter: {
                languages_code: {
                  _eq: language === "en" ? "en-US" : "ar-SA", // filter on language and det data with user language
                },
              },
            },
          },
        },
      })
      .then((res) => {
        setCategoriesResArr(
          res.data?.data?.map((el) => ({
            label: el?.translations[0]?.title,
            val: el?.id,
            iconId: el.icon?.id,
          }))
        );
      })
      .catch((err) => console.log(err));

    //eslint-disable-next-line
  }, [language, countryCode]);

  return (
    <>
      <Grid container>
        <Grid item xs={0} sm={8} sx={{ display: { xs: "none", sm: "block" } }}>
          <InputWithBtn
            label="mapServices_filter_search"
            formik={formik}
            name="search"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={{ xs: "space-between", sm: "flex-end" }}
            spacing={1}
          >
            <Typography noWrap>
              {props.totalLayers +
                " " +
                getContentText("mapServices_filter_found")}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {getContentText("mapServices_filter_name")}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <ReusablePopUp
        title={getContentText("mapServices_filter_name") + " :"}
        isOpen={isOpen}
        sendReq={() => {
          formik.submitForm();
          setIsOpen(false);
        }}
        clearHandel={() => {
          formik.values.search = "";
          formik.values.type = [];
          formik.values.category = [];
          formik.values.featured = true;
          formik.submitForm();
          setIsOpen(false);
        }}
        handleClose={() => {
          formik.values.search =
            props.apiQuary.filter?.translations?.title._contains || "";
          formik.values.type = props.apiQuary.filter?.plan?.name?._in || [];
          formik.values.category =
            props.apiQuary.filter?.workspace?.id?._in || [];
          formik.values.featured = props.apiQuary.sort;

          setIsOpen(false);
        }}
      >
        <Box sx={{ display: { sm: "none" } }}>
          <InputTextCustom
            formik={formik}
            name="search"
            label={getContentText("mapServices_filter_search")}
          />
          <Divider />
        </Box>

        <ReuseableSwitch
          formik={formik}
          name="featured"
          label={getContentText("mapServices_filter_feature_name")}
          des={getContentText("mapServices_filter_feature_des")}
        />

        <Divider />

        <CheckBoxReusable
          formik={formik}
          name="type"
          label={getContentText("mapServices_filter_plan")}
          arr={typeFilterData}
        />
        <Divider />
        <ReuseableSelectedBtn
          arr={categoriesResArr}
          formik={formik}
          name="category"
          label={getContentText("mapServices_filter_category")}
        />
      </ReusablePopUp>
    </>
  );
};

export default ControlOfLayerBaseMapArea;
