import Markdown from "markdown-to-jsx";
import CopyArea from "../Areas/CopyArea";
import { Card } from "@mui/material";
import { useSelector } from "react-redux";

const MarkDownHOC = ({ children }) => {
  const language  = useSelector((state) => state.translation.language);
  return (
    <Card
      sx={{
        my : 4 , 
        px: 4,
        py :1, 
        "& *": language === "ar" && {
          textAlight: "right",
          direction: "rtl",
        },
        ...styleBox,
      }}
    >

      
      <Markdown
        options={{
          overrides: {
            code: { component: CopyArea },
            h1: {
              component: "h2",
            },
            h2: {
              component: "h3",
            },
            h3: {
              component: "h4",
            },
            h4: {
              component: "h5",
            },
            img: {
              component: "img",
            },
          },
        }}
      >
        {children || " "}
      </Markdown>
    
    </Card>
  );
};

export default MarkDownHOC;

const styleBox = {
  "& h2 ,& h3, & h4  , & h5 ": { color: "secondary.main", mt: 2.5 },
  "& p , & li": { fontSize: ".9rem", my: 0.5 },
  "& code": { my: 2 },
  "& img": {
    width: { xs: "100%", sm: "500px", md: "600px" },
    my: 3,
    display: "block",
  },
  "& ul": {
    listStyleType: "none",
    fontFamily: "Pilots",
    color: " #1a1a1a",
  },

  "& ul li:before": {
    content: "'-'",
    color: "secondary.main",
    mr: 1,
  },

  "& a , & a:hover": {
    color: "primary.main",
  },
};
