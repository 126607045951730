import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Title from "../Text/Title";
import Description from "../Text/Description";
import Typography from "@mui/material/Typography";
import useContentHook from "../../hooks/useContentHook";

const textStyle = {
  fontSize: "2.8rem",
  lineHeight: "3rem",
  color: "primary.main",
  p: 0,
  mt: 5,
};

const UsageCard = (props) => {
  
  const { getContentText } = useContentHook();

  return (
    <Card sx={{ padding: 2, textAlign: "center" }}>
      <CardContent>
        <Title text={getContentText(props.data.service)} />

        <Typography variant="h4" component={"h4"} sx={textStyle}>
          {(props.data.total_usage > props.data.service_limit
            ? props.data.service_limit
            : props.data.total_usage) || 0}
          {props.currentMonth && (
            <Description text={`/${props.data.service_limit || 0}`} />
          )}
        </Typography>
      </CardContent>
      {props.currentMonth && (
        <Box sx={{ p: 2, background: "#f6f8f9" }}>
          <Description
            active
            isError={!props.data.active_service}
            text={
              props.data.active_service
                ? getContentText("usage_active")
                : getContentText("usage_inactive")
            }
          />
        </Box>
      )}
    </Card>
  );
};

export default UsageCard;
