import { Box, Card, CardContent, Typography, Link, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getStaticAssetURL } from "../utils/helperFuncations";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useContentHook from "../hooks/useContentHook";
import PriceCard from "../Components/Cards/PriceCard";
import axios from "axios";

const Plan = () => {
  const navigate = useNavigate();
  const { getContentText } = useContentHook();
  const globalVars = useSelector((state) => state.globalVars);
  const [isFreePlan, setIsFreePlan] = useState(true);

  useEffect(() => {
    if (globalVars?.token?.Authorization === "Bearer undefined") return;

    axios
      .get(`${globalVars.ApiUrl}secrets/v1/keys/usage`, {
        headers: globalVars.token,
      })
      .then((res) => {
        if (res.data.subscription_en === "Premium Plan") {
          setIsFreePlan(false);
        } else {
          setIsFreePlan(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVars.ApiUrl, globalVars.token]);

  return (
    <Box>
      <Grid container spacing={6} py={3}>
        <Grid item xs={12} lg={4}>
          <Card sx={cardStyle}>
            <CardContent sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6">
                {getContentText("plan_desCard_start")}
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: 800 }}>
                {getContentText("plan_desCard_with")}
              </Typography>
              <Typography sx={{ mt: 3, fontSize: ".9rem" }}>
                {getContentText("plan_desCard_content")}
              </Typography>
            </CardContent>
            <Box sx={boxImgStyle} />
          </Card>
        </Grid>

        {["free", "paid"].map((el) => (
          <Grid key={el} item xs={12} md={6} lg={4}>
            <PriceCard type={el} key={el} isFreePlan={isFreePlan} />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ my: 5 }}>
        <Typography variant="h4" component="h2" color="secondary">
          {getContentText("plan_rateAndLimits_heading")}
        </Typography>

        <Card sx={{ mt: 3 }}>
          <CardContent component="ul" sx={{ pl: 6, py: 4 }}>
            {getContentText("plan_rateAndLimits_term")}
            <ul>
              <li>
                {getContentText("plan_rateAndLimits_connectTerm")}

                <Link
                  color="primary.main"
                  underline="none"
                  sx={linkStyle}
                  onClick={() => {
                    navigate("/contact-us");
                  }}
                >
                  {getContentText("sideNav_contact_us")}.
                </Link>
              </li>
            </ul>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Plan;

const linkStyle = {
  mx: 1.5,
  borderBottom: 1,
  cursor: "pointer",
  "&:hover ": { color: "primary.main" },
};

const cardStyle = {
  borderRadius: 4,
  px: 2,
  bgcolor: "primary.main",
  height: "100%",
  color: "white",
  display: "flex",
  flexDirection: "column",
  minHeight: "430px",
};

const boxImgStyle = {
  height: "100% ",
  backgroundImage: `url(${getStaticAssetURL("plans-description.png")})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
};
