import { Grid, Skeleton, Stack } from "@mui/material";
import { v4 as uuid } from "uuid";

const UsageLoader = () => {
  return (
    <>
      <Grid container spacing={4} py={5}>
        {[1, 2, 3, 4 , 5 , 6].map(() => (
          <Grid key={uuid()} item xs={12} md={6} xl={4}>
            <Skeleton variant="rectangular" height={260} />
          </Grid>
        ))}
      </Grid>
      <Stack
        direction={{ sm: "row", md: "column", xl: "row" }}
        justifyContent="space-between"
        sx={{ py: 4 }}
      >
        {[1, 2].map(() => (
          <Grid key={uuid()} item xs={12} md={6} xl={4}>
            <Skeleton
              variant="text"
              sx={{ fontSize: "2rem", width: { xs: "100%", sm: 250 } }}
            />
          </Grid>
        ))}
      </Stack>
    </>
  );
};

export default UsageLoader;
