import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popUpShowed:  false ,
  val : null
};

export const PopUpsSlice = createSlice({
  name: "popUpShowed",
  initialState,
  reducers: {
    showPopUpHandel: (state , action) => {
      state.popUpShowed = true;
      state.val = action.payload;
    },
    hiddenPopUpHandel: (state) => {
      state.val  =null
      state.popUpShowed = false;
    },
  },
});

export const { showPopUpHandel, hiddenPopUpHandel } = PopUpsSlice.actions;

export default PopUpsSlice.reducer;
