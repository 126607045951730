import { useSelector } from "react-redux";

const useContentHook = () => {
  const { translationOpj } = useSelector((state) => state.translation);

  const getContentText = (key) => {
    const translateContentArr = translationOpj[key]?.split("\n");

    return !translationOpj[key] ? (
      key
    ) : translateContentArr.length > 1 ? (
      <ul>
        {translateContentArr.map((el) => (
          <li key={el}>{el}</li>
        ))}
      </ul>
    ) : (
      translationOpj[key]
    );
  };

  return {
    getContentText,
  };
};

export default useContentHook;
