import InputTextCustom from "../Components/Inputs/InputTextCustom";
import LoadBtn from "../Components/Inputs/LoadBtn";
import axios from "axios";
import { useState } from "react";
import { useFormik } from "formik";
import { contactSchema } from "../utils/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { Card, Stack } from "@mui/material";
import { showNotificationHandel } from "../Redux/sliceReducers/notificationsSlice";
import {
  catalogTabaqatApi,
  contactInfoArr,
  contactInitialVals,
} from "../utils/StaticVariables";
import useContentHook from "../hooks/useContentHook";
const Contact = () => {
  const dispatch = useDispatch();

  const { chosenCountryPhoneNum } = useSelector((state) => state.globalVars);
  const { getContentText } = useContentHook();

  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: contactInitialVals,
    validationSchema: contactSchema,
    onSubmit: (values) => {
      const apiReqVal = {
        ...values,
        phone_number: chosenCountryPhoneNum + values.phone_number,
      };
      setIsLoading(true);

      axios
        .post(catalogTabaqatApi + "/contact", apiReqVal)
        .then(() => {
          formik.resetForm();
          dispatch(
            showNotificationHandel({
              msg: getContentText("account_contactMsg"),
            })
          );
        })
        .catch(() => {
          dispatch(
            showNotificationHandel({
              msg: getContentText("account_errorMsg"),
              variant: "error",
            })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  return (
    <Stack sx={{ minHeight: "85vh" }}>
      <Card component="form" sx={formStyle}>
        {contactInfoArr.map((el) => (
          <InputTextCustom
            key={el.name}
            formik={formik}
            {...el}
            label={getContentText(el.label)}
          />
        ))}

        <LoadBtn loading={isLoading} fun={formik.submitForm} />
      </Card>
    </Stack>
  );
};
export default Contact;

const formStyle = {
  p: { xs: 1.5, sm: 2, md: 4 },
  m: "auto",
  width: { xs: "100%", md: "75%" },
  maxWidth: "900px",
};
