/* eslint-disable no-template-curly-in-string */
import { Box } from "@mui/material";
import MarkDownHOC from "../Components/HOC/MarkDownHOC";
import TabsReusable from "../Components/Reusable/TabsReusable";
import ToggleBtnsGroups from "../Components/Inputs/ToggleBtnsGroups";
import { useState, useEffect } from "react";
import SelectChosenKey from "../Components/Areas/SelectChosenKey";
import axios from "axios";
import { useSelector } from "react-redux";
import { catalogTabaqatApi } from "../utils/StaticVariables";

const Documentation = (props) => {
  const [chosenGisApp, setChosenGisApp] = useState();
  const [gisAppsArr, setGisAppsArr] = useState([]);
  const [typeMapServiceCalVal, setTypeMapServiceCalVal] = useState();
  const { chosenAssessKey } = useSelector((state) => state.userkeys);
  const language  = useSelector((state) => state.translation.language);

  useEffect(() => {
    axios
      .get(
        `${catalogTabaqatApi}/platform_documentation?fields=software_translations.software,software_translations.service,software_translations.Documentation&deep[translations][_filter][languages_code][_eq]=en-US&filter[status]=published&filter[${
          (props.layers && "Map_Services_Page") ||
          (props.baseMap && "Basemaps_Page") ||
          "Documentation_Page"
        }][_eq]=true`
      )
      .then((res) => {
        const softwareArrRes = [];

        res.data.data.forEach((el) => {
          const thisItem = el.software_translations[0];

          const softwareItemIndx = softwareArrRes.findIndex(
            (softwareEl) => softwareEl.title === thisItem.software
          );

          if (softwareItemIndx > -1) {
            softwareArrRes[softwareItemIndx].service?.push({
              typeService: thisItem.service,
              documentation: thisItem.Documentation,
            });
          } else {
            softwareArrRes.push({
              title: thisItem.software,
              service: [
                {
                  typeService: thisItem.service,
                  documentation: thisItem.Documentation,
                },
              ],
            });
          }
        });

        setGisAppsArr(softwareArrRes);
        handleChangeGisApp(softwareArrRes[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.baseMap, props.layers]);

  const handleChangeGisApp = (newValue) => {
    setChosenGisApp(newValue);
    setTypeMapServiceCalVal(newValue.service[0]);
  };

  const handleChangeMapServiceType = (event, newValue) => {
    if (!newValue) return;
    setTypeMapServiceCalVal(newValue);
  }

  return (
    <>
      <Box my={2}>
        <SelectChosenKey />
      </Box>

      <TabsReusable
        arr={gisAppsArr}
        val={chosenGisApp?.title}
        changeHandel={handleChangeGisApp}
      />
      {chosenGisApp?.service.length > 1 && (
        <ToggleBtnsGroups
          arr={chosenGisApp?.service}
          val={typeMapServiceCalVal}
          changeHandel={handleChangeMapServiceType}
        />
      )}

      <MarkDownHOC>
        {typeMapServiceCalVal &&
          typeMapServiceCalVal?.documentation
            .replaceAll(
              "${layer}",
              typeMapServiceCalVal.typeService === "BASE MAP"
                ? (props.data && props?.data?.id) || "{BASEMAP-ID}"
                : props.data
                ? props?.data.workspace + "/" + props?.data?.id
                : "{CATALOG-ID/lAYER-ID}"
            )
            .replaceAll("${type-name}", props?.data?.id || "{lAYER-ID}")
            .replaceAll("${assess-key}", chosenAssessKey || "YOUR-ASSESS-KEY")
            .replaceAll("${user-language}", language || "en")}
       
      </MarkDownHOC>
    </>
  );
};

export default Documentation;
