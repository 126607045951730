import axios from "axios";
import { Button, Typography , Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { showNotificationHandel } from "../../Redux/sliceReducers/notificationsSlice";
import { useState, useEffect } from "react";
import CopyArea from "../Areas/CopyArea";
import InfoCard from "../Cards/InfoCard";
import useContentHook from "../../hooks/useContentHook";
import ReusablePopUp from "../PopUp/ReusablePopUp";
import DesBtn from "../Inputs/DesBtn";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const Keys = () => {
  // states
  const [disable, setDisable] = useState(false); // state of making generate button disable if user have more than 5 keys
  const [openDetailsPopUp, setOpenDetailsPopUp] = useState(false);
  const [selectedSerialData, setSelectedSerialsData] = useState(false);
  const [serialsData, setSerialsData] = useState([]); // when user select any key to add restriction on  it i put the value of key opj here to send it as a props to components
  const dispatch = useDispatch();
  const { getContentText } = useContentHook();
  const globalVars = useSelector((state) => state.globalVars); // get values  from redux

  useEffect(() => {
    if (serialsData?.length >= 3) {
      setDisable(true);
    }
  }, [serialsData?.length]);

  useEffect(() => {
    getUserSerialsNum();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getUserSerialsNum = () => {
    axios
      .get(`${globalVars.ApiUrl}secrets/v1/keys/get-user-serials/`, {
        headers: globalVars.token,
      })
      .then((res) => {
        setSerialsData(res.data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showNotificationHandel({
            msg: "pages_keys_errorMsg",
            variant: "error",
          })
        );
      });
  };
  // generate new key
  const generateSerialsHandel = () => {
    axios
      .post(
        `${globalVars.ApiUrl}secrets/v1/keys/generate-serial-installer/`,
        {},
        { headers: globalVars.token }
      )
      .then(() => {
        getUserSerialsNum();
        dispatch(showNotificationHandel({ msg: "pages_keys_successMsg" }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showNotificationHandel({
            msg: "pages_keys_errorMsg",
            variant: "error",
          })
        );
      });
  };

  // components
  //generate new kay btn
  const Action = (
    <Button
      disabled={disable}
      onClick={generateSerialsHandel}
      size="small"
      variant="contained"
      sx={{ textTransform: "capitalize" }}
    >
      {getContentText("pages_serial_generate")}
    </Button>
  );

  // map my keys component
  // i put that here because it repeated 2 times
  // once to active kay and second to retire key
  // i put tis component here to accuses  the state easier than props
  const allKeysMapComponent = (keys) =>
    keys?.map((el) => {
      return (
        <Stack direction="row" spacing={1} my={2}>
          <CopyArea icon keys={el.serial_number} text={el.serial_number} />
          <DesBtn copy
            text="details"
            fun={() => {
              setOpenDetailsPopUp(true);
              setSelectedSerialsData(el);
            }}
          >
            <InfoOutlinedIcon />
          </DesBtn>
        </Stack>
      );
    });

  return (
    <>
      <InfoCard
        title={getContentText("pages_serials_title")}
        action={Action}
        content={getContentText("pages_serials_des")}
        alert={allKeysMapComponent(serialsData)}
      />
      <ReusablePopUp
        isOpen={openDetailsPopUp}
        title="details"
        handleClose={() => {
          setOpenDetailsPopUp(false);
          setSelectedSerialsData({});
        }}
      >
        <div>
          {Object.keys(selectedSerialData).map((key) => (
            <p key={key}>
              <Typography
                component="b"
                sx={{ fontWeight: 800, textTransform: "capitalize" }}
              >      
                {key?.replaceAll("_" , " ")} :{" "}
              </Typography>{" "}
              {selectedSerialData[key] || " unknown "}
            </p>
          ))}
        </div>
      </ReusablePopUp>
    </>
  );
};

export default Keys;
