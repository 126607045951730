// rect libraries
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
// redux
import { useDispatch, useSelector } from "react-redux";
import { showPopUpHandel } from "../Redux/sliceReducers/PopUpsSlice";

//cards and inputs components
import MapCard from "../Components/Cards/MapCard";

// usable components areas
import NoResult from "../Components/Areas/NoResult";

// map services components
import ControlOfLayerBaseMapArea from "../Components/MapServices/ControlOfLayerBaseMapArea";
import PaginationCatalog from "../Components/MapServices/PaginationCatalog";

// loaders and pop up components
import SkeletonCardsMapServices from "../Components/Loaders/SkeletonCardsMapServices";
import Documentation from "./Documentation";
import ReuseableDrawer from "../Components/PopUp/ReuseableDrawer";

// static variables and global funcs
import { getAssetGetserversLayersImageURL } from "../utils/helperFuncations";
import {
  tabaqatKayLayer,
  catalogTabaqatApi,
  geoServerUrl,
} from "../utils/StaticVariables";

const MapServices = () => {
  // variables  and states

  const dispatch = useDispatch(); // redux edit function
  const language = useSelector((state) => state.translation.language);
  const countryCode = useSelector((state) => state.country.countrySlice);
  const [layer, setLayer] = useState([]); // api layer map services data
  const [page, setPage] = useState(1); // chosen page number of data result from api
  const [totalLayers, setTotalLayers] = useState(0); // total number of pages count
  const [isDataLayersLoading, setIsDataLayersLoading] = useState(true); // check if the data loading and response take more time
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [openUsePopUp, setOpenUsePopUp] = useState(false);

  const [apiQuary, setApiQuary] = useState({ sort: true });


  const handleCloseUsePopUp = () => {
    setOpenUsePopUp(false);
  };

  const updateFilterDataOpj = (val) => {
    setPage(1);

    let filterNewQuary = {};

    filterNewQuary.sort = val.featured;

    if (val.search) {
      filterNewQuary.filter = {
        translations: {
          title: {
            _contains: val.search,
          },
        },
      };
    }

    if (val.category.length > 0) {
      filterNewQuary.filter = {
        ...filterNewQuary.filter,
        workspace: {
          id: {
            _in: val.category,
          },
        },
      };
    }

    if (val.type.length > 0) {
      filterNewQuary.filter = {
        ...filterNewQuary.filter,
        plan: {
          name: {
            _in: val?.type,
          },
        },
      };
    }

    setApiQuary(filterNewQuary);
  };

  // call api to get the layer map services data
  useEffect(() => {
    setIsDataLayersLoading(true);
    axios
      .get(
        ` ${catalogTabaqatApi}/layer?&deep[translations][_filter][languages_code][_eq]=${
          language === "en" ? "en-US" : "ar-SA"
        }&deep[ ][translations][_filter][languages_code][_eq]=${
          language === "en" ? "en-US" : "ar-SA"
        }`,
        {
          params: {
            sort: apiQuary.sort ? "-featured" : "featured",
            limit: 12,
            page: page,
            meta: "total_count,filter_count",
            fields:
              "organization.geoserver_url,data_source.data_source_id.translations.*,id,plan.name,workspace.translations.title,workspace.name, workspace.icon.id,name,wms_url,wfs_url,translations.title,translations.abstract",
            filter: {
              organization: {
                code: {
                  _eq: countryCode,
                },
              },
              status: {
                _eq: "published",
              },
              ...apiQuary.filter,
            },
          },
        }
      )
      .then((res) => {
        setLayer(res.data.data);
        setTotalLayers(res.data.meta.filter_count); // get the number of total page when we divining total number layers on number of cards (12)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsDataLayersLoading(false); // stop loading
      });
  }, [apiQuary, countryCode, language, page]); // dependencies  states if any of those change this func will fire again

  // change page number
  const handleChangePage = (event, newPage) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPage(newPage);
  };

  // open pop up use to use layer links with any type
  const showUsePopUpUseHandel = (el) => {
    setSelectedLayer({
      id: el?.name,
      workspace: el?.workspace?.name,
      name: el?.translations[0]?.title,
    });
    setOpenUsePopUp(true);
  };

  // open map tarria pop up
  const openShowMapPopUpHandle = (el) => {
    const source = el.data_source[0]?.data_source_id?.translations.find(
      (el) => el.languages_code === (language === "en" ? "en-US" : "ar-SA")
    )?.authority_name;

    const valPayLoad = {
      isMap: true,
      id: el?.id,
      layers: el?.name,
      name: el?.translations[0]?.title,
      url: `${
        (el?.geoserver_url?.organization || geoServerUrl) + el?.workspace.name
      }/${
        el?.name
      }/ows?service=WMS&version=1.3.0&request=GetCapabilities&AcceptLanguages=${language}&access_token=${tabaqatKayLayer}`,
      abstract: el?.translations[0]?.abstract,
      des: el.workspace?.translations[0]?.abstract,
      source,
    };

    dispatch(showPopUpHandel(valPayLoad)); // send layer data as payload opj to redux to open it
  };

  return (
    <>
      <ReuseableDrawer
        isOpen={openUsePopUp}
        handleClose={handleCloseUsePopUp}
        title={selectedLayer?.name}
      >
        <Documentation data={selectedLayer} layers />
      </ReuseableDrawer>

      <Box sx={boxStyle}>
        <ControlOfLayerBaseMapArea
          totalLayers={totalLayers}
          filterHandle={updateFilterDataOpj}
          apiQuary={apiQuary}
        />

        {/*  render loading section when result haven't arrived yet   */}
        {isDataLayersLoading ? (
          <SkeletonCardsMapServices />
        ) : (
          <>
            {/* if result is empty rendering no data component */}
            {layer.length === 0 ? (
              <NoResult />
            ) : (
              <Grid container py={3} spacing={6}>
                {/* looping  on layer result data and render it as group of cards  */}
                {layer?.map((el, indx) => (
                  <Grid item key={el?.id + indx} xs={12} sm={6} lg={4} xl={3}>
                    <MapCard
                      showFun={() => openShowMapPopUpHandle(el)} // that func fire when user click on show btn and it open tarria pop up
                      useFun={() => {
                        // that func fire when user click on use btn and it open use links pop up
                        showUsePopUpUseHandel(el);
                      }}
                      // det image src by category and layer names
                      imgSrc={getAssetGetserversLayersImageURL(
                        el?.workspace.name,
                        el?.name,
                        el?.organization.geoserver_url
                      )}
                      name={el?.translations[0]?.title}
                      des={el?.translations[0]?.abstract}
                      category={el?.workspace}
                      plan={el?.plan?.name}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            <PaginationCatalog
              total={Math.ceil(totalLayers / 12)}
              page={page}
              handleChangePage={handleChangePage}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default MapServices;

// styles
const boxStyle = { maxWidth: "90vw", m: "auto", minHeight: "85vh" };
