import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoader: false,
};

export const globalLoader = createSlice({
  name: "isLoader",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoader = true;
    },
  },
});

export const { startLoading } = globalLoader.actions;

export default globalLoader.reducer;
