import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: localStorage.language || "ar",
  translationOpj: {},
};

export const translationSlice = createSlice({
  name: "translation",
  initialState,
  reducers: {
    setTranslation: (state, action) => {
      action.payload.translationArr.forEach((item) => {
        state.translationOpj[item.key] = item.translations[0]?.value;
      });
    },
    setChangeLanguage: (state) => {
      const toggleLanguage = state.language === "ar" ? "en" : "ar";
      localStorage.language = toggleLanguage;
      state.language = toggleLanguage;
    },
  },
});

export const { setTranslation, setChangeLanguage } = translationSlice.actions;

export default translationSlice.reducer;
