import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ApiUrl: "https://gateway.tabaqat.net/",
  token: "",
  chosenCountryPhoneNum : "966"
};

export const rejesterSlice = createSlice({
  name: "API-URL",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = { Authorization: `Bearer ${action.payload}` }
    },
    updateChosenCountryPhoneNum : (state , action)=>{
      state.chosenCountryPhoneNum= action.payload
    }
  },
});

export const { setToken , updateChosenCountryPhoneNum } = rejesterSlice.actions;

export default rejesterSlice.reducer;
