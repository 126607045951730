import { useRef, useEffect } from "react";
import "maplibre-gl/dist/maplibre-gl.css";
import maplibregl from "maplibre-gl";
import { Box } from "@mui/system";

import CircularProgress from "@mui/material/CircularProgress";
import { tabaqatKeyBaseMap } from "../../utils/StaticVariables";
export default function Map(props) {
  const mapContainerRef = useRef(null);
  const map = useRef(null);

  
  //generate map
  useEffect(() => {
    if (map.current) return;
    map.current = new maplibregl.Map({
      container: mapContainerRef.current,
      style: `https://basemaps.tabaqat.net/styles/bright/style.json?access_token=${tabaqatKeyBaseMap}`,
      center: [45, 26],
      zoom: 3.5,
      attributionControl: false,
    });
    
    map.current.on("load", function (e) {
      // Do something when the source has finished loading
      return map.current.loaded();
    });

    return () => {
      map.current.remove();
    };
  }, []);

  
  //? comment add marker 
  
  // const [generatedMarker, setGeneratedMarker] = useState(null);
  // const addMarker = (coordinates) => {
  //   const marker = new maplibregl.Marker({ color: "#FF0000" })
  //     .setLngLat(coordinates)
  //     .addTo(map.current);

  //   setGeneratedMarker(marker);
  // };

  const replaceMarker = (coordinates) => {
 
    map.current.flyTo({
      center: coordinates,
      zoom: 14,
      bearing: 0,
      speed: 1,
      curve: 4,
      easing: function (t) {
        return t;
      },
      essential: true,
    });
  };

  useEffect(() => {
    if (!props.coordinates) return;
    replaceMarker(props.coordinates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coordinates]);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <Box
        ref={mapContainerRef}
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="secondary"   />
      </Box>
    </Box>
  );
}
