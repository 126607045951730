import { Typography } from "@mui/material";
import useContentHook from "../../hooks/useContentHook";

const NoResult = () => {
  const { getContentText } = useContentHook();

  return (
    <Typography variant="h4" sx={{ p: 7,my : 4,  textAlign: "center", width: "100%" , color: "secondary.main" }}>
     {getContentText("mapServices_noResult")}
    </Typography>
  );
};

export default NoResult;
