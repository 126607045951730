import { TextField, MenuItem } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { changechosenAssessKey } from "../../Redux/sliceReducers/keySlice";
import useContentHook from "../../hooks/useContentHook";

export default function SelectchosenAssessKey() {

  const dispatch = useDispatch();
  const { getContentText } = useContentHook();
  const  userkeys  = useSelector((state) => state.userkeys);


  const handleChange = (e) => {
    dispatch(changechosenAssessKey({ chosenAssessKey: e.target.value }));
  };

  return (
    <>
      {userkeys.activeArr.length > 0 && (
        <TextField
          sx={{
            background: "white ",
          }}
          select
          label={getContentText("keyInput_label")}
          value={userkeys.chosenAssessKey}
          onChange={handleChange}
          fullWidth
        >
          {userkeys.activeArr?.map((option) => (
            <MenuItem key={option.generated_keys} value={option.generated_keys}>
              {option.description
                ? option.description + " - " + option.generated_keys
                : option.generated_keys}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
}
