import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin:  false
};

export const rejecterSlice = createSlice({
  name: "isLogin",
  initialState,
  reducers: {
    loginHandel: (state , action) => {
      state.isLogin = true;
    },
    logOutHandel: (state) => {
      state.isLogin = false;
      localStorage.removeItem("token")
    },
    
  },
});

export const { loginHandel, logOutHandel ,checkIsLog} = rejecterSlice.actions;

export default rejecterSlice.reducer;
