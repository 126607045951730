import { configureStore } from '@reduxjs/toolkit'
import rejecterSlice from "./sliceReducers/rejecterSlice"
import notificationsSlice from "./sliceReducers/notificationsSlice"
import PopUpsSlice from "./sliceReducers/PopUpsSlice"
import globalVarsSlice from "./sliceReducers/globalVarsSlice"
import globalLoaderSlice from "./sliceReducers/globalLoaderSlice"
import countrySlice from "./sliceReducers/countrySlice"
import keySlice from "./sliceReducers/keySlice"
import translationSlice from "./sliceReducers/TranslationSlice"


export const store = configureStore({
  reducer: {
    userkeys : keySlice, 
    country :countrySlice, 
    translation : translationSlice, 
    isLogin : rejecterSlice ,
    isShowed : notificationsSlice , 
    popUpShowed : PopUpsSlice , 
    globalVars : globalVarsSlice,
    globalLoader : globalLoaderSlice,
  },
})