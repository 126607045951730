import InfoCard from "../Cards/InfoCard";
import KeyGenerator from "./KeyGenerator";
import ReusablePopUp from "../PopUp/ReusablePopUp";
import Restrictions from "./Restrictions";
import axios from "axios";
import { Button, Typography, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { showNotificationHandel } from "../../Redux/sliceReducers/notificationsSlice";
import { useState, useEffect } from "react";
import {
  setMyKeys,
  changechosenAssessKey,
} from "../../Redux/sliceReducers/keySlice";
import useContentHook from "../../hooks/useContentHook";

const ApiKeys = () => {
  // states
  const [disable, setDisable] = useState(false); // state of making generate button disable if user have more than 5 keys
  const [isRestrictionPopUpOpen, setIsRestrictionPopUpOpen] = useState(false); // value checked if is restriction pop up is open or no
  const [selectEditKay, setSelectEditKay] = useState(null); // when user select any key to add restriction on  it i put the value of key opj here to send it as a props to components
  const [restrictionOpj, SetRestrictionOpj] = useState({}); // when user update of key opj t put the new opj here to over write the original opj

  const dispatch = useDispatch();
  const { getContentText } = useContentHook();
  const globalVars = useSelector((state) => state.globalVars); // get values  from redux
  const  userkeys = useSelector((state) => state.userkeys); // get values  from redux

  // this function  take a selected key item and over write it with the updated values on restriction or
  // activation and edit des and make one opj that must send to api
  // if the opj change the request will felid with invalid parameter error
  const ApiOpjQuary = (keyItem, updatedKays) => {
    const {
      generated_keys,
      description,
      is_active,
      ip_list,
      http_list,
      restriction_type,
    } = keyItem;

    const parameterEditOpj = {
      generated_keys,
      is_active,
      description: description || "",
      restriction_type,
      ip_list: ip_list || [],
      http_list: http_list || [],
      ...updatedKays, //any data at update keys  will over write the val at this opj so it must be at end
    };

    editMyKeyApIReq(parameterEditOpj); //then i send the new opj to edit key api
  };

  // check  if list of keys  more than 5 items to disable the generate btn
  useEffect(() => {
    if (userkeys.retiredArr.length + userkeys.activeArr.length >= 5) {
      setDisable(true);
    }
  }, [userkeys]);

  // send put req to edit any key
  // it takes parameter opj and send it to api
  const editMyKeyApIReq = (val) => {
    axios
      .put(`${globalVars.ApiUrl}secrets/v1/keys/update-key-details`, val, {
        headers: globalVars.token,
      })
      .then((res) => {
        setIsRestrictionPopUpOpen(false);
        dispatch(setMyKeys({ keyArr: res.data }));
        dispatch(showNotificationHandel({ msg: "pages_keys_successMsg " }));
        dispatch(
          changechosenAssessKey({
            chosenAssessKey: res.data.find((el) => el.is_active)
              ?.generated_keys,
          })
        );
      })
      .catch(() => {
        dispatch(
          showNotificationHandel({
            msg: "pages_keys_errorMsg",
            variant: "error",
          })
        );
      });
  };

  // generate new key
  const generatekeyHandel = () => {
    axios
      .post(
        `${globalVars.ApiUrl}secrets/v1/keys/generate-key/`,
        {},
        { headers: globalVars.token }
      )
      .then((res) => {
        dispatch(setMyKeys({ keyArr: res.data }));
        dispatch(showNotificationHandel({ msg: "pages_keys_successMsg" }));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showNotificationHandel({
            msg: "pages_keys_errorMsg",
            variant: "error",
          })
        );
      });
  };

  // components
  //generate new kay btn
  const Action = (
    <Button
      disabled={disable}
      onClick={generatekeyHandel}
      size="small"
      variant="contained"
      sx={{ textTransform: "capitalize" }}
    >
      {getContentText("pages_keys_generate")}
    </Button>
  );

  // map my keys component
  // i put that here because it repeated 2 times
  // once to active kay and second to retire key
  // i put tis component here to accuses  the state easier than props
  const allKeysMapComponent = (keys) =>
    keys?.map((el) => (
      <KeyGenerator
        data={el}
        key={el?.generated_keys || ""}
        updateHandel={ApiOpjQuary}
        handleOpen={(item) => {
          setSelectEditKay(item);
          setIsRestrictionPopUpOpen(true);
        }}
      />
    ));

  return (
    <>
      {/* active kays section */}
      <InfoCard
        title={getContentText("pages_keys_activeTitle")}
        action={Action}
        content={getContentText("pages_keys_dataActive")}
        alert={allKeysMapComponent(userkeys.activeArr)}
      />
      {/* retired kes section */}
      <InfoCard
        title={getContentText("pages_keys_retiredTitle")}
        content={getContentText("pages_keys_dataRetired")}
        alert={allKeysMapComponent(userkeys.retiredArr)}
      />

      {/* restriction pop up HOC  */}
      <ReusablePopUp
        isOpen={isRestrictionPopUpOpen}
        sendReq={() => {
          ApiOpjQuary(selectEditKay, restrictionOpj);
        }}
        handleClose={() => {
          setIsRestrictionPopUpOpen(false);
        }}
        title={
          <Typography
            color="secondary.main"
            component="p"
            sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
          >
            <Box component="span" sx={{ fontWeight: "600" }}>
              {selectEditKay?.description
                ? selectEditKay?.description + " - "
                : null}
            </Box>
            <Box component="span" sx={{ fontWeight: "500" }}>
              {selectEditKay?.generated_keys}
            </Box>
          </Typography>
        }
      >
        <Restrictions
          data={selectEditKay}
          updateRestrictionOpj={SetRestrictionOpj}
        />
      </ReusablePopUp>
    </>
  );
};

export default ApiKeys;
