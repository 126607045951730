import { Stack , Pagination} from "@mui/material";

const PaginationCatalog = (props) => {
  return (
    <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ my: 6 }}
  >
    {props.total === 1 || !props.total ? null : (
      <Pagination
        count={props.total}
        page={props.page}
        onChange={props.handleChangePage}
        color="secondary"
        size="small"
      />
    )}
  </Stack>
  );
};

export default PaginationCatalog;
