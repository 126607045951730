import {
  DialogTitle,
  DialogContent,
  Drawer,
  Stack,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hiddenPopUpHandel } from "../../Redux/sliceReducers/PopUpsSlice";
import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DesBtn from "../Inputs/DesBtn";
import { tabaqatKeyBaseMap , geoServerUrl } from "../../utils/StaticVariables";
import ReusablePopUp from "../PopUp/ReusablePopUp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useContentHook from "../../hooks/useContentHook";
import { terriaUrl , basemapUrl} from "../../utils/StaticVariables";

export default function MapPopUop() {
  const dispatch = useDispatch();
  const [isDesPopUpOpened, setIsDesPopUpOpened] = useState(false);
  const language  = useSelector((state) => state.translation.language);
  const  popUpShowed  = useSelector((state) => state.popUpShowed);
  const { getContentText } = useContentHook();

  const refIFreamTarria = useRef(null);

  const handleClose = () => {
    dispatch(hiddenPopUpHandel());
  };


  const reqTarria = (e) => {
    if (!popUpShowed?.val?.id) return;
    const iframeWindow = refIFreamTarria.current?.contentWindow;
    if (e.source === iframeWindow && e.data === "ready") {
      iframeWindow.postMessage(
        {
          initSources: [
            {
              initialCamera: {
                north: 30.390489396093574,
                east: 62.986841147401385,
                south: 16.293443380030926,
                west: 28.72117778092538,
              },
              workbench: [popUpShowed.val.name],
              catalog: [
                {
                  type: "group",
                  name: "External",
                  members: [
                    {
                      id: popUpShowed.val.name,
                      type: "wms",
                      name: popUpShowed.val.name,
                      description: popUpShowed.val.des,
                      url: popUpShowed?.val?.url || geoServerUrl,
                      layers: popUpShowed.val.layers,
                      zoomOnAddToWorkbench: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
        terriaUrl
      );
    }
  };

  useEffect(() => {
    window.addEventListener("message", reqTarria);
    return () => {
      window.removeEventListener("message", reqTarria);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpShowed.val]);

  const srcIframe = popUpShowed.val?.isMap
    ? `${terriaUrl}/#mode=preview&hideExplorerPanel=1?lng=${language}`
    : `${basemapUrl}/styles/${popUpShowed.val?.id}/?access_token=${tabaqatKeyBaseMap}#5.04/25.54/44.85`;

  return (
    <div>
      <Drawer
        anchor="bottom"
        open={popUpShowed.popUpShowed}
        onClose={handleClose}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pr: 1 }}
        >
          <DialogTitle noWrap sx={titleStyle} component="div">
            {popUpShowed?.val?.abstract && (
              <DesBtn
                fun={() => setIsDesPopUpOpened(true)}
                text={getContentText("popUp_details")}
                place="bottom"
                close
                primary
              >
                <InfoOutlinedIcon sx={{ fontSize: "1.5rem" }} />
              </DesBtn>
            )}
            <Box component="span" px={1}>
              {popUpShowed.val?.name}
            </Box>
          </DialogTitle>
          <DesBtn fun={handleClose} close text={getContentText("popUp_cancel")}>
            <CloseIcon />
          </DesBtn>
        </Stack>
        <DialogContent dividers component="div" sx={mapBoxStyle}>
          <iframe
            ref={refIFreamTarria}
            title={getContentText(popUpShowed.val?.name)}
            height="100%"
            width="100%"
            id="embeddedTabaqatMap"
            src={srcIframe}
          ></iframe>
        </DialogContent>
      </Drawer>
      {(popUpShowed?.val?.abstract || popUpShowed?.val?.source) && (
        <ReusablePopUp
          title={getContentText("popUp_details")}
          isOpen={isDesPopUpOpened}
          handleClose={() => {
            setIsDesPopUpOpened(false);
          }}
        >
          <Divider />
          {popUpShowed?.val?.abstract && (
            <>
              <Typography sx={popUpContentStyle} color="secondary.main">
                {getContentText("popUp_description")}
              </Typography>

              <Typography sx={popUpTextStyle}>
                {popUpShowed?.val?.abstract}
              </Typography>
            </>
          )}

          {popUpShowed?.val?.source && (
            <>
              <Typography sx={popUpContentStyle} color="secondary.main">
                {getContentText("popUp_source")}
              </Typography>

              <Typography sx={popUpTextStyle}>
                {popUpShowed?.val?.source}
              </Typography>
            </>
          )}
        </ReusablePopUp>
      )}
    </div>
  );
}

const mapBoxStyle = {
  height: "100vh",
  minHeight: "300px",
  m: 0,
  p: 1,
  overflow: "hidden",
  position: "relative",
};

const titleStyle = {
  fontSize: { xs: ".8rem", md: "1rem" },
  width: "calc(100% - 40px)",
  p: 0.75,
};

const popUpContentStyle = { mt: 2, mb: 1, fontWeight: "800" };
const popUpTextStyle = { fontSize: { xs: ".8rem", md: "1rem" } };
