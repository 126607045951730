import MapCard from "../Components/Cards/MapCard";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showPopUpHandel } from "../Redux/sliceReducers/PopUpsSlice";
import Documentation from "./Documentation";
import { catalogTabaqatApi } from "../utils/StaticVariables";
import axios from "axios";
import { getAssetURL } from "../utils/helperFuncations";
import SkeletonCardsMapServices from "../Components/Loaders/SkeletonCardsMapServices";
import ReuseableDrawer from "../Components/PopUp/ReuseableDrawer";
import useContentHook from "../hooks/useContentHook";

const BaseMap = () => {
  const dispatch = useDispatch();

  const [openUsePopUp, setOpenUsePopUp] = useState(false);
  const [selectedBaseMap, setSelectedBaseMap] = useState({});
  const [isDataBaseMapsLoading, setIsDataBaseMapsLoading] = useState(false);
  const [layerData, setLayerData] = useState(null);

  const language  = useSelector((state) => state.translation.language);

  const { getContentText } = useContentHook();

  const handleCloseUsePopUp = () => {
    setOpenUsePopUp(false);
  };

  useEffect(() => {
    setIsDataBaseMapsLoading(true);
    axios
      .get(
        catalogTabaqatApi +
          "/basemaps?" +
          {
            params: {
              fields: "order,basemap_id,name_ar,name_en,image",
              sort: "order",
              "filter[status]": "published",
            },
          }
      )
      .then((res) => {
        setLayerData(res?.data?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsDataBaseMapsLoading(false); // stop loading
      });
  }, []);

  // open pop up use to use layer links with any type
  const showUsePopUpUseHandel = (el) => {
    setSelectedBaseMap({
      id: el?.basemap_id,
      name: language === "en" ? el?.name_en : el?.name_ar,
    });
    setOpenUsePopUp(true);
  };

  // generate dynamic link based of map type

  const openShowMapPopUpHandle = (el) => {
    const valPayLoad = {
      isMap: false,
      type: "baseMaps",
      id: el?.basemap_id,
      name: language === "en" ? el?.name_en : el?.name_ar,
    };
    dispatch(showPopUpHandel(valPayLoad));
  };

  return (
    <>
      <ReuseableDrawer
        isOpen={openUsePopUp}
        handleClose={handleCloseUsePopUp}
        title={selectedBaseMap.name}
      >
        <Documentation data={selectedBaseMap} baseMap />
      </ReuseableDrawer>

      {isDataBaseMapsLoading ? (
        <SkeletonCardsMapServices />
      ) : (
        <Grid container py={3} spacing={6}>
          {layerData?.map((el) => (
            <Grid item key={el.basemap_id} xs={12} sm={6} lg={4} xl={3}>
              <MapCard
                baseMap
                data={el}
                imgSrc={getAssetURL(el?.image)}
                useFun={() => showUsePopUpUseHandel(el)}
                showFun={() => openShowMapPopUpHandle(el)}
                name={language === "en" ? el?.name_en : el?.name_ar}
                des={
                  <>
                    <b> {getContentText("baseMaps_style")} : </b>
                    {el?.basemap_id}
                  </>
                }
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};
export default BaseMap;
