import {
  IconButton,
  Menu,
  Avatar,
  MenuList,
  Stack,
  ListItemIcon,
  Typography,
  MenuItem,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import useContentHook from "../../hooks/useContentHook";
import SelectCustom from "../Inputs/SelectCustom";
import axios from "axios";
import { useDispatch , useSelector} from "react-redux";
import { changeCountryHandel } from "../../Redux/sliceReducers/countrySlice";
import { catalogTabaqatApi } from "../../utils/StaticVariables";

export default function MenuNav(props) {
  const { keycloak } = useKeycloak();
  const { getContentText } = useContentHook();
  const isNotLoggedIn = keycloak.authenticated;
  const [anchorEl, setAnchorEl] = useState(null);
  const [countriesArr, setCountriesArr] = useState([]);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.translation.language);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandel = () => {
    localStorage.removeItem("token");
    keycloak.logout();
  };

  const logInHandle = () => {
    keycloak.login();
  };

  const changeCountryHandle = (val) => {
    dispatch(changeCountryHandel(val));
  };

  useEffect(() => {
    axios
      .get(
        
        `${catalogTabaqatApi}/organization?filter[status]=published&fields=id,name,code,logo,name_ar&filter[visibility]=visible&filter[type]=country`
      )
      .then((res) => {
    
        setCountriesArr(
          res.data.data.map((el) => ({ val: el.code, label: language === "ar" ?  el.name_ar : el.name}))
        );
      })
      .catch((err) => console.log(err));
  }, [language]);

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <SelectCustom
          small
          label="sideNav_region"
          arr={countriesArr}
          changeHandle={changeCountryHandle}
        />
      </Box>

      <IconButton onClick={handleClick}>
        <Avatar sx={iconStyle}>{props.name}</Avatar>
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList>
          <MenuItem onClick={isNotLoggedIn ? logoutHandel : logInHandle}>
            <Typography sx={{ pr: 1 }} variant="inherit">
              {getContentText(
                isNotLoggedIn ? "account_logout" : "account_login"
              )}
            </Typography>
            <ListItemIcon>
              {isNotLoggedIn ? (
                <LogoutIcon fontSize="small" sx={{ ml: "auto" }} />
              ) : (
                <LoginIcon fontSize="small" sx={{ ml: "auto" }} />
              )}
            </ListItemIcon>
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  );
}

const iconStyle = {
  bgcolor: { xs: "#fff", md: "secondary.main" },
  color: { xs: "secondary.main", md: "#fff" },
  width: 35,
  height: 35,
  textTransform: "uppercase",
};
