import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Grid } from "@mui/material";
import { monthsArr } from "../utils/StaticVariables";
import axios from "axios";
import SelectCustom from "../Components/Inputs/SelectCustom";
import UsageCard from "../Components/Cards/UsageCard";
import Notes from "../Components/Text/Notes";
import UsageLoader from "../Components/Loaders/UsageLoader";
import useContentHook from "../hooks/useContentHook";

const Usage = () => {
  const [userUsage, setUserUsages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [monthVal, setMonthVal] = useState(1);
  const [keyArr, setKeyArr] = useState([]);
  const [userMonthsArr, setUserMonthsArr] = useState([]);
  const [keyVal, setKeyVal] = useState("");
  const { getContentText } = useContentHook();
  const userkeys = useSelector((state) => state.userkeys);
  const globalVars = useSelector((state) => state.globalVars);
  const { language } = useSelector((state) => state.translation);

  useEffect(() => {
    if (!monthVal || !keyVal) return;
    setIsLoading(true);

    axios
      .get(`${globalVars.ApiUrl}secrets/v1/keys/usage`, {
        headers: globalVars.token,
        params: { month: monthVal, token: keyVal },
      })
      .then((res) => {
        setUserUsages(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [globalVars.ApiUrl, globalVars.token, monthVal, keyVal]);

  useEffect(() => {
    const userMonth = new Date().getMonth();

    const newDataArr = [0, 1, 2].map((el) => {
      switch (userMonth - el) {
        case -1:
          return monthsArr[11];
        case -2:
          return monthsArr[10];
        default:
          return monthsArr[userMonth - el];
      }
    });

    setUserMonthsArr(newDataArr);
  }, []);

  useEffect(() => {
    const newkeyArr = [...userkeys.activeArr, ...userkeys.retiredArr].map(
      (el) => ({
        label: el.description
          ? language === "ar"
            ? el.generated_keys + " ( " + el.description + " ) "
            : " ( " + el.description + " ) " + el.generated_keys
          : el.generated_keys,

        val: el.generated_keys,
      })
    );

    setKeyArr([{ label: "usage_all", val: "*" }, ...newkeyArr]);
  }, [language, userkeys]);

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={3}
        py={2}
        justifyContent={{ sm: "flex-end" }}
      >
        <SelectCustom
          activeKays
          arr={keyArr}
          label="sideNav_keys"
          changeHandle={(val) => {
            setKeyVal(val);
          }}
        />

        <SelectCustom
          arr={userMonthsArr}
          label="date_month"
          changeHandle={(val) => {
            setMonthVal(val);
          }}
        />
      </Stack>

      {isLoading ? (
        <UsageLoader />
      ) : (
        <>
          <Grid container spacing={4} py={5}>
            {userUsage?.services?.map((el) => (
              <Grid key={el.service} item xs={12} md={6} xl={4}>
                <UsageCard
                  currentMonth={monthVal === new Date().getMonth() + 1}
                  data={el}
                />
              </Grid>
            ))}
          </Grid>

          {monthVal === new Date().getMonth() + 1 && (
            <Stack
              direction={{ sm: "row", md: "column", xl: "row" }}
              justifyContent="space-between"
              sx={{ py: 4 }}
            >
              <Notes
                title={getContentText("usage_subscription")}
                value={
                  language === "ar"
                    ? userUsage?.subscription_ar
                    : userUsage?.subscription_en
                }
              />
              <Notes
                title={getContentText("usage_renew")}
                value={userUsage?.renewal_date}
              />
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default Usage;
