import {
  Card,
  CardContent,
  Typography,
  CardActions,
  List,
} from "@mui/material";
import PlanBtn from "../PartPages/PlanBtn";
import useContentHook from "../../hooks/useContentHook";

const PriceCard = (props) => {
  const { getContentText } = useContentHook();

  return (
    <Card sx={cardStyle}>
      <CardContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: 800, color: "secondary.main" }}
        >
         
          {props.type === "free"
            ? getContentText("plan_title_free")
            : getContentText("plan_title_paid")}
        </Typography>
      
        <Typography sx={{ my: 1.5 }} color="text.secondary">
          {getContentText("plan_benefits")}
        </Typography>
        <List>
          {props.type !== "free"
            ? getContentText("plan_paid_limit_des")
            : getContentText("plan_free_limit_des")
     
      
  }
        </List>
      </CardContent>
      <CardActions>
        <PlanBtn type="props.type" {...props} />
      </CardActions>
    </Card>
  );
};

export default PriceCard;

const cardStyle = {
  borderRadius: 4,
  p: 2,
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
};
