import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeArr: [],
  retiredArr: [],
  chosenAssessKey: "" , 
};

export const keySlice = createSlice({
  name: "userkeys",
  initialState,
  reducers: {
    setMyKeys: (state, action) => {
      const keys = action.payload.keyArr;
      const sortedKeys = keys.sort((objA, objB) => objA.id - objB.id);
      state.activeArr = sortedKeys?.filter((el) => el.is_active);
      state.retiredArr = sortedKeys?.filter((el) => !el.is_active);
    },
    changechosenAssessKey: (state, action) => {
      state.chosenAssessKey = action.payload.chosenAssessKey;
    },
  },
});

export const { setMyKeys , changechosenAssessKey } = keySlice.actions;

export default keySlice.reducer;
