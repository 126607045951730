import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowed:  false ,
  val : null
};

export const notificationsSlice = createSlice({
  name: "isShowed",
  initialState,
  reducers: {
    showNotificationHandel: (state , action) => {
      state.isShowed = true;
      state.val = action.payload;
    },
    hiddenNotificationHandel: (state) => {
      state.isShowed = false;
    },
  },
});

export const { showNotificationHandel, hiddenNotificationHandel } = notificationsSlice.actions;

export default notificationsSlice.reducer;
