import { contaboStorageURL } from "./StaticVariables";
import { geoServerUrl } from "./StaticVariables";

export function getAssetGetserversLayersImageURL(
  workspace,
  layer,
  geoServer = geoServerUrl
) {
  if (!layer) return null;
  return `${
    geoServer + workspace
  }/wms?service=WMS&version=1.1.0&request=GetMap&layers=${workspace}%3A${layer}&bbox=34.57222222222222%2C16.369444444444444%2C55.638888888888886%2C32.56388888888889&width=768&height=590&srs=EPSG%3A4326&access_token=mapsaudi-a2nTyh6mgoKC2yju76Pg4G&styles=&format=image%2Fpng`;
}

export function getAssetURL(id) {
  if (!id) return null;
  return `${
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL_PRODUCTION
      : process.env.REACT_APP_API_URL_DEVELOPMENT
  }assets/${id}`;
}

export function getStaticAssetURL(id) {
  if (!id) return null;
  return `${contaboStorageURL}8496006d59724e76a11e0661b45ef60e:platform-content/assets%2F${id}`;
}
