import { Box } from "@mui/material";
const Map = () => {
  return (
    <Box sx={boxStyle}>
      <iframe
        title="map"
        src="https://map.tabaqat.net/"
        height={"100%"}
        width={"100%"}
      ></iframe>
    </Box>
  );
};

export default Map;

const boxStyle = {
  background: "white",
  width: "100%",
  minHeight: "300px",
  height: "calc(100vh - 120px)",
  borerRadius: "20px",
  // p
  overflow: "hidden"
  
};
