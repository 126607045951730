import Box from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Welcome from "../Components/PartPages/Welcome";
import { getStaticAssetURL } from "../utils/helperFuncations";

const logoStyle = {
  backgroundImage: `url(${getStaticAssetURL("logo.png")})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "  center ",
  width: "130px",
  height: "50px",
  backgroundColor: "secondary.main",
  boxSadow: "none",
};

const boxStyle = {
  backgroundColor: "secondary.main",
  position: "absolute",
  height: "100%",
  width: "50%",
  left: "0",
  zIndex: -1,
  borderRadius: "0px",
};

const Rejecter = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ height: "85vh", position: "relative", minHeight: "400px" ,  }}
    >
      <Box sx={boxStyle}>
        <Stack sx={logoStyle} />
      </Box>
      <Welcome />
    </Stack>
  );
};

export default Rejecter;
