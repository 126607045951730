import Map from "../Components/Geocoding/Map";
import { Box } from "@mui/system";
import AutoComplatePlaces from "../Components/Geocoding/AutoComplatePlaces";
import { useState } from "react";
const Geocoding = () => {
  const [coordinates, setCoordinates] = useState(null);

  return (
    <Box sx={boxStyle}>
      <Box sx={layoutStyle}>
        <AutoComplatePlaces updateCoordinates={setCoordinates} />
      </Box>
      <Map coordinates={coordinates} />
    </Box>
  );
};

export default Geocoding;

const layoutStyle = {
  position: "absolute",
  width: "100%",
  height: "100%",
  background: "#50505040",
  zIndex: "1",
};

const boxStyle = {
  position: "relative",
  width: "100%",
  minHeight: "300px",
  height: "calc(100vh - 120px)",
};
