import {
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  Alert,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import InfoCard from "../Cards/InfoCard";
import InputWithBtn from "../Inputs/InputWithBtn";
import ReusableList from "../Areas/ReusableList";
import { restrictionTypeArr, freePlanId } from "../../utils/StaticVariables";
import { useFormik } from "formik";
import { restrictionSchema } from "../../utils/validationSchema";
import { useDispatch } from "react-redux";
import { showNotificationHandel } from "../../Redux/sliceReducers/notificationsSlice";
import { useNavigate } from "react-router-dom";
import useContentHook from "../../hooks/useContentHook";

export default function Restrictions(props) {
  const [cardLabel, setCardLabel] = useState("");
  const [listRestrictionUrls, setListRestrictionUrls] = useState([]);
  const [allowAdding, setAllowAdding] = useState(true);
  const [typeOfReq, setTypeOfReq] = useState("");

  const { getContentText } = useContentHook();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      restriction: "",
      type: "",
    },
    validationSchema: typeOfReq !== "none" && restrictionSchema(typeOfReq),
    onSubmit: (values) => {
   
        !listRestrictionUrls.includes(values.restriction)
        ? setListRestrictionUrls([...listRestrictionUrls, values.restriction])
        : dispatch(
            showNotificationHandel({
              msg: getContentText("restriction_added"),
              variant: "error",
            })
          );
    },
  });

  useEffect(() => {
    props.data.plan_id !== freePlanId
      ? setAllowAdding(true)
      : listRestrictionUrls?.length < 5
      ? setAllowAdding(true)
      : setAllowAdding(false);
  }, [listRestrictionUrls, props.data]);

  useEffect(() => {
    const updatedKaysOnApiOpj = {
      restriction_type: formik.values.type,
      [formik.values.type + "_list"]: listRestrictionUrls,
    };
    props.updateRestrictionOpj(updatedKaysOnApiOpj);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type, listRestrictionUrls]);

  useEffect(() => {
    if (!formik.values.type) formik.values.type = props.data?.restriction_type;

    const selectedType = restrictionTypeArr.find(
      (el) => el.value === formik?.values.type
    );

    formik.touched.restriction = false;
    formik.values.restriction = "";

    setCardLabel(selectedType?.label);
    setListRestrictionUrls(props.data[formik?.values.type + "_list"] || []);
    setTypeOfReq(formik?.values.type);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, formik?.values.type]);

  const removeLinkFromRestriction = (val) => {
    const newArrWithoutSelectedLink = listRestrictionUrls?.filter(
      (el) => el !== val
    );
    setListRestrictionUrls(newArrWithoutSelectedLink);
  };

  return (
    <>
      <Typography variant="h5" color="secondary.main" sx={{ mt: 2 }}>
        {getContentText("restriction_key_title")}
      </Typography>
      <Typography sx={{ my: 1 }}>{getContentText("restriction_key_des")}</Typography>
      <Typography variant="h5" color="secondary.main" sx={{ mt: 2 }}>
        {getContentText("restriction_application_title")}
      </Typography>
      <Typography sx={{ my: 1 }}>{getContentText("restriction_application_des")}</Typography>

      <RadioGroup
        name="type"
        value={formik?.values.type}
        onChange={formik?.handleChange}
      >
        {restrictionTypeArr?.map((el) => (
          <FormControlLabel
            key={el.value + el?.label}
            value={el?.value}
            label={getContentText(el?.label)}
            control={<Radio />}
          />
        ))}
      </RadioGroup>

      <InfoCard
        title={getContentText(cardLabel)}
        content={
          formik?.values.type === "none" ? (
            <p> {getContentText("restriction_none")}</p>
          ) : (
            <>
              <ReusableList
                arr={listRestrictionUrls}
                iconClickHandle={removeLinkFromRestriction}
              />

              {allowAdding && (
                <InputWithBtn
                  disabled={listRestrictionUrls?.length > 5}
                  helpDes
                  formik={formik}
                  name="restriction"
                />
              )}
            </>
          )
        }
        />
        {props.data.plan_id === freePlanId && (
          <Alert severity="warning" mt={2}>
            {getContentText("restriction_limitNote")}{" "}
            <Link
              underline="none"
              sx={{
                mx: 0.5,
                borderBottom: 1,
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              {getContentText("sideNav_contact")}.
            </Link>
          </Alert>
        )}
    </>
  );
}
