import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countrySlice: "sa" ,
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    changeCountryHandel: (state, action) => {
      state.countrySlice = action.payload;
    },
  },
});

export const { changeCountryHandel } = countrySlice.actions;

export default countrySlice.reducer;
