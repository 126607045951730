import InfoCart from "../Components/Cards/InfoCard";
import Button from "@mui/material/Button";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import useContentHook from "../hooks/useContentHook";

const DashBoard = () => {
  const { getContentText } = useContentHook();

  const date = <p> {getContentText("pages_dashBoard_data")}</p>;

  const Action = (
    <Button size="small" variant="outlined" sx={{ mx: 1 }}>
      Learn More
    </Button>
  );
  return (
    <>
      <InfoCart
        content={date}
        action={Action}
        icon={<VolunteerActivismIcon />}
      />
      <InfoCart
        content={date}
        action={Action}
        icon={<VolunteerActivismIcon />}
      />
      <InfoCart content={date} icon={<VolunteerActivismIcon />} />
      <InfoCart content={date} />
    </>
  );
};

export default DashBoard;
