import CopyArea from "../Areas/CopyArea";
import InputWithBtn from "../Inputs/InputWithBtn";
import DesBtn from "../Inputs/DesBtn";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useFormik } from "formik";
import { Stack, Box } from "@mui/material";
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined";
import useContentHook from "../../hooks/useContentHook";
const KeyGenerator = (props) => {
  const { getContentText } = useContentHook();

  const formik = useFormik({
    initialValues: {
      description: props.data.description || "",
    },
    onSubmit: (values) => {
      props.updateHandel(props.data, {
        description: values.description,
      });
    },
  });

  const ChangeActiveHandel = () => {
    props.updateHandel(props.data, {
      is_active: !props.data.is_active,
    });
  };

  return (
    <Stack
      sx={{ pt: 2 }}
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      <Box sx={{ flexGrow : 1 }}>
        <CopyArea
          icon
          text={
          props.data.generated_keys
          }
        />
      </Box>
      <Stack
        direction="row"
        component="form"
        onSubmit={formik.handleSubmit}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ minWidth: { xs: "100%", md: "45%", lg: "60%" } }}
      >
       
          <InputWithBtn
            label="pages_keys_description"
            name="description"
            formik={formik}
          />
   
            <DesBtn
              fun={ChangeActiveHandel}
              text={
                props.data.is_active
                  ? getContentText("pages_keys_retiredButtonTitle")
                  : getContentText("pages_keys_reactivateButtonTitle")
              }
            >
              {props.data.is_active ? (
                <PauseIcon sx={{ fontSize: "1.1rem" }} />
              ) : (
                <PlayArrowIcon sx={{ fontSize: "1.1rem" }} />
              )}
            </DesBtn>

            <DesBtn
              fun={() => {
                props.handleOpen(props.data);
              }}
              text={getContentText("restriction_name")}
            >
              <LinkOffOutlinedIcon sx={{ fontSize: "1.1rem" }} />
            </DesBtn>
  
      </Stack>
    </Stack>
  );
};

export default KeyGenerator;
