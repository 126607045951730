import { useState, useEffect } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { prefixer } from "stylis";
import theme from "./utils/theme";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { AppRouter } from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./Redux/sliceReducers/globalVarsSlice";
import SnackAlert from "./Components/Areas/SnackAlert";
import MapPopup from "./Components/PopUp/MapPopup";
import maplibregl from "maplibre-gl";
import { Box } from "@mui/system";
import { setTranslation } from "./Redux/sliceReducers/TranslationSlice";
import axios from "axios";
import { catalogTabaqatApi } from "./utils/StaticVariables";

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ltrTheme = createTheme({ direction: "ltr", ...theme });
const rtlTheme = createTheme({ direction: "rtl", ...theme });

export default function App() {
  const [isRtl, setIsRtl] = useState(true);

  const dispatch = useDispatch();
  const  language = useSelector((state) => state.translation.language);

  useEffect(() => {

    if (language === "ar") {
      setIsRtl(true);
      document.dir = "rtl";
    } else {
      setIsRtl(false);
      document.dir = "ltr";
    }
  }, [language]);
  

  useEffect(() => {
    maplibregl.setRTLTextPlugin(
      "https://unpkg.com/@mapbox/mapbox-gl-rtl-text@0.2.3/mapbox-gl-rtl-text.min.js",
      null,
      true
    );
  }, []);

  useEffect(() => {
    axios
      .get(
        `${catalogTabaqatApi}/Platform_Content?fields=key,translations.value&deep[translations][_filter]
        [languages_code][_eq]=${language === "en" ? "en-US" : "ar-SA"}&filter[status]=published&limit=10000`
      )
      .then((res) => {
        dispatch(setTranslation({ translationArr: res.data.data }));
      })
      .catch((err) => {
        console.log( err);
      });
    // eslint-disable-next-line no-use-before-define
  }, [dispatch, language]);

  const tokenLogger = (tokens) => {
    dispatch(setToken(tokens.token));
  };

  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
        <ReactKeycloakProvider authClient={keycloak} onTokens={tokenLogger}>
          <Box sx={{ background: "#f6f8f9 " }}>
            <AppRouter />
          </Box>
          <MapPopup />
          <SnackAlert />
        </ReactKeycloakProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
