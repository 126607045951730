import Serials from "../Components/Kays/Serials";
import ApiKeys from "../Components/Kays/ApiKeys";
import { Typography } from "@mui/material";
import useContentHook from "../hooks/useContentHook";
const Keys = () => {
    const { getContentText } = useContentHook();
  return (
    <div>
      <Typography
        sx={textStyle} >
      {  getContentText("api-keys")}
      </Typography>
      <ApiKeys />
      <Typography
        sx={textStyle} >
      {  getContentText("software-keys")}
      </Typography>
      <Serials />
    </div>
  );
};

export default Keys;

const textStyle= { fontWeight: 800, fontSize: "2.5rem", color: "primary.main"  }